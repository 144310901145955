<template>
	<div>
		<div class="ij">
	        <div class="hea tablea">
	            <div style="text-align: left;">
	                <svg-icon icon-class="sousuo" class="cx"></svg-icon>
	                <span style="font-size: 13px"  class="xx">筛选搜索</span>
	            </div>
	            <div style="text-align: right;">
	                <svg-icon icon-class="shangjiantou" :class="!shbool?'ac':''" style="color:gray;cursor: pointer" @click="hide"></svg-icon>
	                <span style="font-size: 13px;color:gray;margin-right: 20px;cursor: pointer" @click="hide">收起筛选</span>
	                <el-button type="primary" @click="search" size="small">搜索结果</el-button>
	                <el-button type="primary" @click="bhighSearch" v-show="highBool" size="small">高级检索</el-button>
	            </div>
	        </div>
	        <div class="cont" v-show="shbool">
	            <slot name="content"></slot>
	        </div>
	    </div>

	    <el-dialog :title="title" :visible.sync="dialogFormVisible">
			  <slot name="highSearch"></slot>
		</el-dialog>

	</div>
    
</template>

<script type="text/javascript">
	export default {
		name: 'enterplist',
		props:{
			show:{
				 type: Boolean,
        		 default: true
			},
			highBool:{
				type:Boolean,
				default:false
			},
			title:{
				type:String,
				default:"高级搜索"
			}
		},
		data() {
			return {
				shbool:this.show,
				dialogFormVisible:false
			}
		},
		methods:{
			hide(){
				this.shbool=this.shbool?false:true
			},
			search(){
				this.$emit("search","");
			},
			bhighSearch(){
				this.dialogFormVisible = true;
			}
		}
	}
</script>
<style type="text/css" lang="scss" scoped>

	.ij{
		border:1px solid rgba(128,128,128,0.2);
		margin-bottom:20px;
		
	}
	.xx{
		 webkit-user-select: none;
		  -moz-user-select: none;
		  -ms-user-select: none;
		  user-select: none;
	}
	.ac{
		transform:rotate(180deg);
        -webkit-transform:rotate(180deg);  
        -moz-transform:rotate(180deg); 
	}
	.cont{
		background-color: white;
		border-top: 1px solid rgba(128,128,128,0.2);
		padding-right: 20px;
		padding-left: 20px;
		padding-top: 10px;
		padding-bottom: 10px
	}
	.hea{
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 20px;
		padding-right: 20px;
	}
</style>