/**
 * 日志操作相关API
 */

import request from '@/utils/request'



/**
 * 获取日志
 * @param params
 */
export function getAccountLog(params) {
    return request({
      url: 'admin/log/accountLog',
      method: 'get',
      loading: false,
      params
    })
  }

  /**
 * 批量删除日志
 * @param params
 */
export function deleteAccountLog(ids) {
    return request({
      url: `/admin/log/accountLog/${ids}/delete`,
      method: 'delete',
      loading: false,
    })
  }

  /**
 * 清除日志
 * @param params
 */
export function deletetimeAccountLog(params) {
    return request({
      url: `/admin/log/accountLog/delete/time`,
      method: 'delete',
      loading: false,
      params
    })
  }
 
  
/**
 * 获取管理员列表
 * @param params
 */
export function getAdministratorList(params) {
    return request({
      url: 'admin/systems/manager/admin-users',
      method: 'get',
      loaidng: false,
      params
    })
  }