<template>
  <en-table-layout
    :tableData="tableData.data"
    :loading="loading"
    @selection-change="handleSelectionChange"
  >
    <template slot="toolbar">
      <el-form-item
        label="操作人员"
        class="col-auto"
      >
        <el-select
          v-model="params.account_id"
          clearable
          placeholder="请选择"
          size="medium"
        >
          <el-option
            v-for="item in options1"
            :key="item.id"
            :label="item.username"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="操作日期"
        class="col-auto"
      >
        <el-date-picker
          style="width: 180px;"
          v-model="time"
          type="date"
          placeholder="选择日期"
          size="medium"
          @change="timeChange"
        ></el-date-picker>
      </el-form-item>

      <div class="col-auto">
        <el-button
          @click="search"
          size="small"
          type="primary"
        >
          搜索
        </el-button>
      </div>

      <div class="col"></div>

      <el-form-item
        label="清空日志"
        class="col-auto"
      >
        <el-select
          v-model="time_type"
          clearable
          placeholder="请选择"
          size="medium"
        >
          <el-option
            v-for="item in dateList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <div class="col-auto ml-2">
        <el-button
          @click="handledelete(idsList, false)"
          size="small"
          type="primary"
        >
          确认
        </el-button>
      </div>

      <div class="col-auto ml-2" style="border-left: 2px solid #E5E5E5; padding-left: 8px">
        <el-button
          @click="handledelete(idsList, true)"
          size="small"
          type="primary"
        >
          批量删除
        </el-button>
      </div>
    </template>

    <template slot="table-columns">
      <el-table-column
        type="selection"
        width="55"
      ></el-table-column>
      <el-table-column
        prop="id"
        label="编号"
      />
      <el-table-column
        prop="account_name"
        label="操作者"
      />
      <el-table-column
        prop="create_date"
        label="操作日期"
      >
        <template slot-scope="scope">{{ scope.row.create_date | unixToDate("yyyy-MM-dd hh:mm:ss") }}</template>
      </el-table-column>
      <el-table-column
        prop="ip"
        label="ip"
      />
      <el-table-column
        prop="oper_detail"
        label="操作记录"
      />
    </template>

    <el-pagination
      slot="pagination"
      @size-change="handlePageSizeChange"
      @current-change="handlePageCurrentChange"
      :current-page="tableData.page_no"
      :page-size="tableData.page_size"
      :total="tableData.data_total"
      :page-sizes="MixinPageSizes"
      :layout="MixinTableLayout"
      background
    ></el-pagination>
  </en-table-layout>
</template>

<script>
import * as API_Log from "@/api/log";
import search from "@/components/search/index";
import EnTableLayout from "../../../ui-components/TableLayout/src/main";

export default {
  name: "handleLog",
  components: {
    EnTableLayout,
    search,
  },
  data() {
    return {
      dateList: [
        {label: "一周之前", value: 1},
        {label: "一个月之前", value: 2},
        {label: "三个月之前", value: 3},
        {label: "半年之前", value: 4},
        {label: "一年之前", value: 5},
      ],
      options1: [],
      time: "",
      value: "",
      time_type: "",
      idsList: [],
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        type: 2, //操作日志
        account_type: 4, //账户类型 4大平台
        account_id: "",
      },
      // 商品数据
      tableData: {},
    };
  },
  mounted() {
    this.GET_AccountLogList();
    this.GET_AdministratorList();
  },
  activated() {
    this.GET_AccountLogList();
    this.GET_AdministratorList();
  },
  watch: {},
  methods: {
    GET_AdministratorList() {
      let obj = {
        page_no: 1,
        page_size: 9999,
      };
      API_Log.getAdministratorList(obj).then((res) => {
        this.options1 = res.data;
      });
    },
    timeChange() {
      if (!this.time) {
        this.params.start_time = "";
        this.params.end_time = "";
        return;
      }
      this.params.start_time = new Date(this.time).getTime() / 1000;
      this.params.end_time = new Date(this.time).getTime() / 1000 + 86399;
    },
    search() {
      this.GET_AccountLogList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_AccountLogList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_AccountLogList();
    },
    /** 批量删除 */
    handledelete(ids, type) {
      if (type) {
        console.log(ids, "ids");
        if (ids.length == 0) {
          this.$message.error("请勾选日志");
          return;
        }
        API_Log.deleteAccountLog(ids)
          .then((response) => {
            this.loading = false;
            this.$message.success("批量删除成功");
            this.GET_AccountLogList();
          })
          .catch(() => (this.loading = false));
      } else {
        if (!this.time_type) {
          this.$message.error("请选择要清除日志的时间");
          return;
        }
        ;
        this.loading = true;
        API_Log.deletetimeAccountLog({type: this.time_type})
          .then((response) => {
            this.loading = false;
            this.$message.success("清除成功");
            this.GET_AccountLogList();
          })
          .catch(() => (this.loading = false));
      }
    },

    /** 获取日志 */
    GET_AccountLogList() {
      this.loading = true;
      API_Log.getAccountLog(this.params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => (this.loading = false));
    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.id);
      });
      this.idsList = ids;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
